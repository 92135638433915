import * as React from 'react';

import Img from 'gatsby-image';
import PropTypes from 'prop-types';

import DefaultImage from '../../../assets/images/history.png';

import './VisuelComponent.scss';

let classNames = require('classnames');

const VisuelContent = ({ visuel, alt, size, aspectRationValue }) => {
  const fuild = { ...visuel.fluid };
  const alt_value = alt || '';
  let objectFit = aspectRationValue ? 'cover' : 'contain'
  if (aspectRationValue) {
    fuild.aspectRatio = aspectRationValue;
  }
  return (
    <div className={classNames('visuel', 'size_' + size)}>
      {visuel &&
        <Img
          style={{ height: '100%', width: '100%' }}
          objectFit={objectFit}
          fluid={fuild}
          objectPosition="50% 50%"
          alt={alt_value}
        />
      }
      {!visuel && DefaultImage &&
        <img src={DefaultImage} alt={alt_value} />
      }
    </div>
  );
};

const VisuelComponent = ({ visuel, alt, extremeLeft, extremeRight, size, aspectRationValue, customClass = '' }) => {
  return (
    <>
      {
        extremeLeft ? (
          <div className={classNames('visuel_component', 'cross_wrapper_extreme_left', customClass)}>
            <VisuelContent
              visuel={visuel}
              alt={alt}
              size={size}
              aspectRationValue={aspectRationValue}
            />
          </div>
        ) : extremeRight ? (
          <div className={classNames('visuel_component', 'cross_wrapper_extreme_right', customClass)}>
            <VisuelContent
              visuel={visuel}
              alt={alt}
              size={size}
              aspectRationValue={aspectRationValue}
            />
          </div>
        ) : (
          <div className={classNames('visuel_component', customClass)}>
            <VisuelContent
              visuel={visuel}
              alt={alt}
              size={size}
              aspectRationValue={aspectRationValue}
            />
          </div>
        )
      }

    </>
  );
};

VisuelComponent.defaultProps = {
  visuel: '',
  alt: 'Default Alt',
  size: 'wrapper',
};

VisuelComponent.propTypes = {
  //visuel: PropTypes.object,
  alt: PropTypes.string,
  extremeLeft: PropTypes.bool,
  extremeRight: PropTypes.bool,
  size: PropTypes.string
};

export default VisuelComponent;
