import * as React from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { VerticalYellowLineShape, IconLectureTime } from '../../Atoms/Icons/Icons';
import QuoteMark from '../../Atoms/QuoteMark/QuoteMark';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import TextBlockComponent from '../../components/ContentType/TextBlockComponent/TextBlockComponent';
import VisuelComponent from '../../components/ContentType/VisuelComponent/VisuelComponent';
import Seo from '../../components/Seo/Seo';
import Layout from '../../Layout';
import ScrollIcon from '../../Molecules/ScrollIcon/ScrollIcon';
import SocialShare from '../../Molecules/SocialShare/SocialShare';
import TitleSection from '../../Molecules/TitleSection/TitleSection';

import './article.scss';

let classNames = require('classnames');

const MediaPage = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: {eq: "slider-image.png"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <Seo title="Colas Article Page" />
      <div className={classNames('page_template', 'page_article')}>
        <div className="banner_fullwidth_visuel">
          <div className="visuel">
            {data?.file &&
              <Img
                style={{ height: '100%', width: '100%' }}
                imgStyle={{ objectFit: 'cover' }}
                fluid={data.file.childImageSharp.fluid}
              />
            }
          </div>
          <div className="visuel_overlay">
            <div className="wrapper_page">
              <Breadcrumb
                page_banner={true}
                data={{
                  currentPage: { title: 'Media', url: '/media' }
                }}
              />
            </div>
            <div className="scroll_icon_container">
              <ScrollIcon />
            </div>
          </div>
        </div>
        <div className="article_details page_content">
          <div className="wrapper_page">
            <div div className="share_date">
              <div className="publication_date">
                <p className="date">
                  <span className="day">24</span>
                  <span className="month">Sept</span>
                </p>
                <VerticalYellowLineShape className="bottom_line" />
                <p className="lecture_time">
                  <IconLectureTime className="time_icon" />
                  <span>2’30’’</span>
                </p>
              </div>
              <SocialShare
                vertical={true}
                hidemobile={true}
              />
            </div>
            <div className="wrapper_page_xs">
              <TitleSection
                title="A Paris-Orly, un chantier hors du commun"
              />
              <p className="highlight">
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
              </p>
              <p>
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit Aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?
              </p>
              <VisuelComponent
                extremeLeft={true}
                visuel={data.file.childImageSharp}
                alt="Test"
                size="two_third"
              />
              <TextBlockComponent
                title={{
                  text: 'Lorem ipsum'
                }}
                description={null}
              />
              <TextBlockComponent
                description="Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit Aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"
              />
              <div className="quotemark_container">
                <QuoteMark
                  lang="fr"
                  size="m"
                  textWeight="m"
                  text="Colas ouvre la voie vers un monde plus responsable !"
                />
              </div>
              <TextBlockComponent
                description="Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit Aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"
              />
              <VisuelComponent
                extremeRight={true}
                visuel={data.file.childImageSharp}
                alt="Test"
                size="two_third"
              />
              <TextBlockComponent
                description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam dicta consequatur, id officia eligendi ullam suscipit, sint eaque aperiam voluptatem quia optio nobis tenetur accusantium amet excepturi. Eos, recusandae eius."
              />
              <TextBlockComponent
                title={{
                  type: 'section_title',
                  text: 'Lorem ipsum dolor sit met que vadis',
                  subtitle: 'laoeazea eaizhe aze'
                }}
                description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam dicta consequatur, id officia eligendi ullam suscipit, sint eaque aperiam voluptatem quia optio nobis tenetur accusantium amet excepturi. Eos, recusandae eius."
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default MediaPage;
